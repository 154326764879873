<template>
    <div :class="setClass()">
        <div class="header-wrapper">
            <slot name="header"></slot>
        </div>
        <div :class="{ 'body-wrapper': true, 'body-wrapper-space': !!space}">
            <div class="form-wrapper">
                <slot></slot>
            </div>
            <div class="footer-wrapper">
                <slot name="body-footer"></slot>
            </div>
        </div>
        <div class="footer-wrapper">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<style scoped>
    .form-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #9124C8;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .header-wrapper {
        min-width: 375px;
        min-height: 50px;
        background-color: #9124C8;
        text-align: center;
        line-height: 34.7px;
    }

    .body-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* border-radius: 22px 22px 0 0; */
        background-color: #FFFFFF;
        /* box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.02); */
        /* box-shadow: 0 5px 5px -2px rgba(0, 0, 0, 0.1); */
        min-height: 675px;
    }

    .body-wrapper-space {
        position: fixed;
        top: 100px;
        bottom: 70px;
        overflow-y: scroll;
        background-color: #FAF7F7;
    }

    .body-wrapper .form-wrapper {
        margin: 72px 24px 36px 24px;
        border-radius: 15px;
        overflow-x: hidden;
        /* overflow-y: scroll; */
        background-color: #FAFAFA;
        /* -webkit-overflow-scrolling: touch; */
        /* box-shadow: 0 20px 20px rgba(0, 0, 0, .15); */
        /* box-shadow: 0 0 20px -2px rgba(10, 0, 0, 0.4); */
        transition: all .3s ease-in-out;
        position: relative;
        z-index: 99;
    }

    .body-wrapper-space .form-wrapper {
        margin-top: 18px;
        background-color: #FAFAFA;
        /* box-shadow: 0 20px 20px rgba(0, 0, 0, 0); */
        border-radius: 0;
    }

    .body-wrapper .footer-wrapper {
        margin-left: 36px;
    }

    .footer-wrapper {
        width: 302px;
        font-size: 8px;
        margin-left: 36px;
        margin-right: 36px;
        margin-bottom: 48px;
    }

    .body-cus .body-wrapper {
        padding-bottom: 120px !important;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'formpage',
        props: {
            space: { type: Boolean, default: false },
            klsName: { type: String, default: '' },
        },
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
            setClass() {
                return `form-container ${this.klsName}`;
            }

        }
    };
</script>